import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import _, { debounce } from "lodash";
import Seo from "gatsby-plugin-wpgraphql-seo";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";

import Layout from "../components/layout";
import GoogleAds, { LoadInfSlot } from "../components/google-ads";
import SeoStructure from "../components/seo-structure";
import Avantis from "../components/avantis";
import TaboolaWidget from "../components/taboola-widget";

import useTaboola from "../hooks/useTaboola";
import { isBrowser, getObject, isMobilePredicate, loadScript } from "../utils";
import { defaultLanguage } from "../i18n";
import LocalesContext from "../context/LocalesContext";

const isVisible = (ele) => {
  const { top, bottom } = ele.getBoundingClientRect();
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  return (top > 0 || bottom > 0) && top < vHeight;
};

// Store previous url to avoid repetition
let prevUrl = "";
// Counter for parallax IDs
let currentParallaxAdIndex = 0;

const scrollHandler = function () {
  const sections = document.querySelectorAll(".section");
  const matcherSectionsUrls = [];

  sections.forEach((section) => {
    const url = section.getAttribute("data-url");

    if (isVisible(section)) {
      matcherSectionsUrls.push(url);
    }
  });

  const currentUrl = matcherSectionsUrls[matcherSectionsUrls.length - 1];

  if (currentUrl !== prevUrl) {
    window.history.replaceState({ path: currentUrl }, "", currentUrl);
  }

  prevUrl = matcherSectionsUrls[matcherSectionsUrls.length - 1];
};

function BlogPost({ data, pageContext }) {
  const post = data.posts.nodes[0];
  const allSections = !!post.nextPageTC ? post.nextPageTC : [];
  const seo = data?.allWpPost?.nodes[0]?.seo;
  const [isMobile, setIsMobile] = useState(false);
  const [windowOrigin, setWindowOrigin] = useState("");

  const [currentListIndex, setCurrentListIndex] = useState(0);
  const currentListIndexRef = useRef(0);
  currentListIndexRef.current = currentListIndex;

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(true);
  //Set a ref for the loading div
  const loadRef = useRef();

  const { setupTaboola } = useTaboola(1);

  let parallaxAdIndex = 0;

  // Setup Taboola Web Push
  useEffect(() => {
    setupTaboola();
  }, [setupTaboola]);

  // Handle intersection with load more div
  const handleObserver = (entities) => {
    const target = entities[0];

    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  // Initialize the intersection observer API
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loadRef.current) {
      observer.observe(loadRef.current);
    }

    // Setup listener for url modify
    window.addEventListener("scroll", debounce(scrollHandler, 100));
  }, []);

  // Handle loading more articles
  useEffect(() => {
    const isMobile = isMobilePredicate();
    
    if (loadMore && hasMore) {
      setCurrentListIndex((prevIndex) => {
        if (isMobile) {
          LoadInfSlot(
            currentListIndexRef.current,
            `MPU_Parallax_${prevIndex + 1 + currentParallaxAdIndex}`
          );
          LoadInfSlot(
            currentListIndexRef.current,
            `MPU_Parallax_${prevIndex + 1 + 1 + currentParallaxAdIndex}`
          );
        } else {
          LoadInfSlot(
            currentListIndexRef.current,
            `LeaderDesktopInfinite_${prevIndex + 1}`
          );

          // LoadInfSlot(
          //   currentListIndex,
          //   `LeaderDesktopInfinite_${
          //     prevIndex + 1 + 1 + currentParallaxAdIndex
          //   }`
          // );
        }

        currentParallaxAdIndex++;

        return prevIndex + 1;
      });
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  //Check if there is more
  useEffect(() => {
    const isMore = currentListIndex < allSections.length;

    setHasMore(isMore);
  }, [currentListIndex]); //eslint-disable-line

  useEffect(() => {
    const isMobile = isMobilePredicate();

    setIsMobile(isMobile);
    setWindowOrigin(window.location.origin);

    /* Setup Parallax */
    if (isMobile) {
      loadScript(
        "https://sdk.prod.pbstck.com/d662901/parallax.js",
        false,
        false,
        true
      );
    }
  }, []);

  const setupOptioin = (index) => {
    return {
      replace: (node) => {
        if (!isBrowser()) {
          return;
        }

        if (!node.attribs) {
          return;
        }

        if (
          node.attribs &&
          node.attribs.class &&
          node.attribs.class.indexOf("gatsby-image-wrapper") !== -1
        ) {
          const picture = getObject(node.children, "name", "picture");
          const image = getObject(picture.children, "name", "img");
          const src = image.attribs["data-src"];
          const srcSet = image.attribs["data-srcset"];
          const sizes = image.attribs["sizes"];

          return (
            <>
              {index === 0 && isMobile && (
                <div
                  id="LeaderBelowTitle"
                  className="code-block code-block--banner"
                >
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div className="ad-container" />
                </div>
              )}

              {index > 0 && isMobile && (
                <div style={{ minWidth: "300px" }}>
                  <adk-parallax
                    id={`MPU_Parallax_${index + parallaxAdIndex}`}
                    className="ad-container"
                  />
                </div>
              )}
    
              <img src={src} sizes={sizes} srcSet={srcSet} alt=''/>

              {index > 0 && !isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div
                    id={`LeaderDesktopInfinite_${index}`}
                    className="ad-container"
                  />
                </div>
              )}

              {index === 0 && isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div id="MPUTopRight" className="ad-container" />
                </div>
              )}

              {index > 0 && isMobile && (
                <div style={{ minWidth: "300px" }}>
                  <adk-parallax
                    id={`MPU_Parallax_${index + 1 + parallaxAdIndex}`}
                    className="ad-container"
                  />
                </div>
              )}
            </>
          );
        }

        if (node.attribs && node.attribs.src) {
          return (
            <>
              {index === 0 && isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div id="LeaderBelowTitle" className="ad-container" />
                </div>
              )}

              {index > 0 && isMobile && (
                <div style={{ minWidth: "300px" }}>
                  <adk-parallax
                    id={`MPU_Parallax_${index + parallaxAdIndex}`}
                    className="ad-container"
                  />
                </div>
              )}

              <img src={node.attribs.src} alt='' />

              {index > 0 && !isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div
                    id={`LeaderDesktopInfinite_${index}`}
                    className="ad-container"
                  />
                </div>
              )}

              {index === 0 && isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div id="MPUTopRight" className="ad-container" />
                </div>
              )}

              {index > 0 && isMobile && (
                <div style={{ minWidth: "300px" }}>
                  <adk-parallax
                    id={`MPU_Parallax_${index + 1 + parallaxAdIndex}`}
                    className="ad-container"
                  />
                </div>
              )}
            </>
          );
        }

        return;
      },
    };
  };

  if (_.isEmpty(post)) return null;

  //Pubstack Refresh/Dashboard tag
  const pubstackTag = process.env.GATSBY_PUBSTACK_TAG;
  const excerptWithoutTags = post.excerpt.replace(/(<([^>]+)>)/gi, "");
  const canonicalSlug = `/${pageContext.originalSlug}`;

  const locale =
    pageContext.locale !== defaultLanguage ? `/${pageContext.locale}` : "/en";
    
  return (
    <>
      <Helmet>
        <script async src={pubstackTag} type="text/javascript" />
      </Helmet>
      <GoogleAds
        type="inf"
        page={pageContext.page + 1}
        lang={pageContext.locale}
      />
      <Seo post={seo} />
      <SeoStructure
        title={post.title}
        description={excerptWithoutTags}
        slug={canonicalSlug}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "@id": `${!post?.id ? "" : post?.id}`,
            datePublished: `${new Date(post?.date)}`,
            dateModified: `${new Date(post?.modified)}`,
            headline: `${!post?.title ? "" : post?.title}`,
            author: {
              "@type": "Person",
              name: `${
                !post?.author?.node?.name ? "" : post?.author?.node?.name
              }`,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `${!post?.slug ? "" : post?.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${!process.env.SITE_NAME ? "" : process.env.SITE_NAME}`,
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            image: {
              "@type": "ImageObject",
              url: `${
                !post?.featuredImage?.node?.link
                  ? ""
                  : post?.featuredImage?.node?.link
              }`,
              width: `${
                !post?.featuredImage?.node?.width
                  ? ""
                  : post?.featuredImage?.node?.width
              }`,
              height: `${
                !post?.featuredImage?.node?.height
                  ? ""
                  : post?.featuredImage?.node?.height
              }`,
            },
          })}
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${post.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
      <article className="post">
        <div className="post-main">
          <header className="post-header">
            <h1 className="entry-title">{post.title}</h1>
            <div className="post-meta">
              <div className="post-author-name">
                <div className="author-by">By</div>
                <span>{post.author.node.name}</span>
                <div className="author-line"> - </div>
              </div>
              <div className="post-date">{post.date}</div>
            </div>
          </header>

          {!isMobile && (
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container" />
            </div>
          )}

          {isMobile && (
            <Avantis tagid={`${process.env.GATSBY_AVANTIS_INF_TAGID_MOBILE}`} />
          )}

          {!isMobile && (
            <Avantis tagid={`${process.env.GATSBY_AVANTIS_INF_TAGID_DESKTOP}`} />
          )}

          <div className="post-content">
            {allSections.map((article, index) => {
              if (index > 1) {
                ++parallaxAdIndex;
              }

              return (
                <div
                  key={index}
                  className={`section`}
                  data-url={`${windowOrigin}${locale}/${
                    pageContext.originalSlug
                  }/${index + 1 === 1 ? "" : index + 1}`}
                  style={{
                    display: index > currentListIndex ? "none" : "block",
                  }}
                >
                  {parse(article, setupOptioin(index))}

                  {index === 0 && isMobile && (
                    <div className="code-block code-block--banner">
                      <div className="divider">
                        <span>Advertisement</span>
                      </div>
                      <div id="MPUCenterRight" className="ad-container" />
                    </div>
                  )}

                  {index === 0 && !isMobile && (
                    <div className="code-block code-block--banner">
                      <div className="divider">
                        <span>Advertisement</span>
                      </div>
                      <div
                        id="LeaderDesktopInfinite"
                        className="ad-container"
                      />
                    </div>
                  )}

                  {/* {index > 0 && !isMobile && (
                    <div className="code-block code-block--banner">
                      <div className="divider">
                        <span>Advertisement</span>
                      </div>
                      <div 
                        id={`LeaderDesktopInfinite_${
                          index + 1 + parallaxAdIndex
                        }`}
                        className="ad-container" 
                      />
                    </div>
                  )} */}
                </div>
              );
            })}
          </div>
          <div ref={loadRef}>{hasMore ? <p>Loading...</p> : null}</div>

          <TaboolaWidget
            mode="thumbnails-d"
            container="TaboolaFeedDesktop"
            placement="Taboola Feed Desktop"
          />

          <div className="code-block code-block--footer">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div id="StickyBanner" className="ad-container" />
          </div>
        </div>

        <aside className="post-ads-sidebar col-1">
          <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="RightColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
        </aside>

        <aside className="post-ads-sidebar col-2">
          <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="LeftColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
        </aside>
      </article>
    </>
  );
}

const BlogPosts = ({ data, pageContext }) => {
  const avaiablePostTranslations = data.avaiablePostTranslations.nodes;
  const avaiableLangs = avaiablePostTranslations.map(
    (post) => post.language.slug
  );

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: pageContext.originalSlug,
          avaiableLangs,
          pageNumber: 0,
        }}
      >
        <Layout containerClasses="container--left">
          <BlogPost data={data} pageContext={pageContext} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default BlogPosts;

export const query = graphql`
  query ($slug: String!, $originalSlug: String!) {
    avaiablePostTranslations: allWpPost(
      filter: { originalSlug: { eq: $originalSlug } }
    ) {
      nodes {
        language {
          slug
        }
      }
    }
    posts: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        content
        nextPageTC
        slug
        excerpt
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            link
            height
            width
          }
        }
        modified(formatString: "MMMM D, YYYY")
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        date(formatString: "MMMM D, YYYY")
      }
    }
  }
`;
