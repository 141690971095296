import React, { useContext, useEffect } from "react";
import TrackingContext from "../context/TrackingContext";
import { isBrowser, loadScript } from "../utils";

function TaboolaWidget( props ) {
    const { utm_source } = useContext(TrackingContext);
    const { mode, container, placement } = props;

    useEffect(() => {
        loadScript(
            "",
            true,
            false,
            false,
            `
                window._taboola = window._taboola || [];
                _taboola.push({article:'auto', tracking_codes:{ 'utm_source': '${utm_source}', 'abtest': 'ab_test_here' } });
                !function (e, f, u, i) {
                if (!document.getElementById(i)){
                    e.async = 1;
                    e.src = u;
                    e.id = i;
                    f.parentNode.insertBefore(e, f);
                }
                }(document.createElement('script'),
                document.getElementsByTagName('script')[0],
                '//cdn.taboola.com/libtrc/trendscatchers-network/loader.js',
                'tb_loader_script');
                if(window.performance && typeof window.performance.mark == 'function')
                {window.performance.mark('tbl_ic');}
            `
        );
        loadScript(
            "",
            false,
            false,
            false,
            `
        console.log('this run?');
            window._taboola = window._taboola || [];
            window._taboola.push({
                mode: '${mode}',
                container: '${container}',
                placement: '${placement}',
                target_type: 'mix',
                tracking_codes: '${utm_source}'
            });
        `)
    }, [mode, container, placement, utm_source]);


    const showTaboola = isBrowser && ( utm_source && !(utm_source.includes("ob-") || utm_source.includes("zemanta") || utm_source.includes("nosource") ) )

    return showTaboola ? (
            <div id={container} />
        ) : null;
    
}

export default TaboolaWidget